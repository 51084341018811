<template>
  <div class="main">
    <div class="left-bar">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>卡片名称</span>
          <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
        </div>
        <div v-for="o in 4" :key="o" class="text item">
          {{ '列表内容 ' + o }}
        </div>
      </el-card>
    </div>
    <div class="mid-bar">
      <div id="map"></div>
    </div>
    <div class="right-bar">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>卡片名称</span>
          <el-button style="float: right; padding: 3px 0" type="text">操作按钮</el-button>
        </div>
        <div v-for="o in 4" :key="o" class="text item">
          {{ '列表内容 ' + o }}
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "test"
}
</script>

<style scoped>
.main {
  display: flex;
  height: calc(100vh - 52px);
  background: #f1f2f5;
  justify-content: center;

}

.left-bar {
  width: 300px;
  padding: 10px;
}

.mid-bar {
  width: calc(100vh - 52px);
  height: calc(100vh - 52px);
  padding: 10px 0;
}

#map {
  height: 100%;
  background-color: #3f474f;
  border-radius: 10px;
}

.right-bar {
  width: 300px;
  padding: 10px;
}
</style>